ul.b {
  list-style-type: square;
  text-align: left;
  margin-left: 30px;
}


#page-container {
  position: relative;
  min-height: 100vh;
}

#footerpics{
  width: 50px;
}
#footerpics2{
  width: 40px;
}




.leftpane {
  width: 30%;
  height: 100%;
  float: left;

  border-collapse: collapse;
}
.canv {
  height: 300vh;
  margin: 0 0;
  background-position: center;
}
.canv2 {
  background-color: #46c6b7;
  height: 300vh;
}

#profilepic {
  max-height: 300px;
  max-width: 300px;
  width: 280px;
  height: 280px;
  top: 500px;
}

.App {
  text-align: center;
}


#border {
  padding-top: 25px;
  padding-bottom: 25px;
}


nav {
  display: flex;
  justify-content: space-around;
  align-self: center;
  min-height: 10vh;
  background: lightblue;
  color: white;
}
  #LiverpoolLogo{
    width:20%;

  }
  #LeedsLogo{
    width:25%;
  }
#ProfPic{
  width:150px;
}
#GenericLogo{
  width: 150px;
}
#MediaLogoSmall{
  width: 50px;
  text-align: right;
}
#GithubLogo{
  width: 130px;
}
.title{
  font-size : 28px;
}


@media only screen and (min-width : 768px ){
  #LiverpoolLogo{
    width:20%;

  }
  #LeedsLogo{
    width:30%;
  }
}
@media only screen and (min-width : 1000px){
  #LiverpoolLogo{
    width:50%;


  }

  #ProfPic{
    width:300px;
  }
  #LeedsLogo{
    width:65%;
  }
}

